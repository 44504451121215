import {
  JsonFormat,
  arrayJsonFormat,
  booleanJsonFormat,
  nullableJsonFormat,
  numberJsonFormat,
  objectJsonFormat,
  stringEnumJsonFormat,
  stringJsonFormat,
  symbolEnumJsonFormat,
  symbolJsonFormat,
  symbolUnionJsonFormat,
  temporalDurationJsonFormat,
} from "@redotech/json/format";
import { bijectionCompose } from "@redotech/util/bijection";
import { instantAddUtc } from "@redotech/util/temporal";
import { ConversationPlatform } from "../conversation";
import { Channel, SalesChannels } from "../sales-channels";
import { ReturnOptionMethod } from "./return-option";

/**
 * Product or chat condition
 */
export type Condition =
  | Condition.Coverage
  | Condition.PackageProtection
  | Condition.Discount
  | Condition.FulfillmentPeriod
  | Condition.FinalSaleReturn
  | Condition.Orderless
  | Condition.ShippingWithin
  | Condition.OrderDate
  | Condition.ReturnDate
  | Condition.OrderWithin
  | Condition.ProductProperty
  | Condition.ProductTag
  | Condition.ProductType
  | Condition.ProductVariantMetafield
  | Condition.OrderTag
  | Condition.CustomerTag
  | Condition.CustomerCountry
  | Condition.ItemsBeingReturnedCount
  | Condition.AdjustmentAmount
  | Condition.ReturnMethod
  | Condition.CompensationMethod
  | Condition.SalesChannel
  | Condition.Collection
  | Condition.Price
  | Condition.NumCustomerClaims
  | Condition.NumCustomerReturns
  | Condition.NumOrders
  | Condition.ReturnRate
  | Condition.ExchangeCount
  | Condition.Sku
  | Condition.Price
  | Condition.PaymentMethod
  | Condition.AfterHours
  | Condition.FulfillmentTrackingUpdateWithin
  | Condition.CustomerEmailSubscriber
  | Condition.CustomerSmsSubscriber
  | Condition.TicketChannel
  | Condition.Topic
  | Condition.ReceivedAtEmail
  | Condition.SentFromEmail
  | Condition.SubjectLine
  | Condition.MessageBodyMatches
  | Condition.OrderCountGreaterThan
  | Condition.TotalSpentGreaterThan
  | Condition.Vendor
  | Condition.TrackingType
  | Condition.IsRefundReturn;

/** This subset of Conditions is specifically for customer support rules. */
export type RuleCondition =
  | Condition.TicketChannel
  | Condition.Topic
  | Condition.SentFromEmail
  | Condition.ReceivedAtEmail
  | Condition.AfterHours
  | Condition.SubjectLine
  | Condition.MessageBodyMatches;

export enum DateOption {
  BEFORE = "Before or on",
  AFTER = "After or on",
  BETWEEN = "Between",
}

export enum PriceOption {
  EQUALS = "Equals",
  LESS_THAN = "Less than",
  GREATER_THAN = "Greater than",
  CENTS_EQUALS = "Cents equals",
}

export enum NumClaimsOption {
  LESS_THAN = "Less than",
  GREATER_THAN = "Greater than",
}

export enum NumReturnsOption {
  LESS_THAN = "Less than",
  GREATER_THAN = "Greater than",
}

export enum NumOrdersOption {
  LESS_THAN = "Less than",
  GREATER_THAN = "Greater than",
}

export enum ReturnRateOption {
  LESS_THAN = "Less than",
  GREATER_THAN = "Greater than",
}

export enum NumItemsBeingReturnedOption {
  LESS_THAN = "Less than",
  GREATER_THAN = "Greater than",
  EQUALS = "Equals",
}

export enum AdjustmentAmountOption {
  LESS_THAN = "Less than",
  GREATER_THAN = "Greater than",
  EQUALS = "Equals",
}

export enum SkuOption {
  EQUALS = "Equals",
  STARTS_WITH = "Starts with",
  ENDS_WITH = "Ends with",
  INCLUDES = "Includes",
}

export enum PaymentMethodOption {
  GIFT_CARD = "Gift Card",
  MANUAL = "Manual",
  OTHER = "Other",
}

export enum DiscountOptionModel {
  ORDER = "order",
  ITEM = "item",
}

export enum DiscountMatchType {
  EXACT = "exact",
  CONTAINS = "contains",
}

export enum MetafieldMatchType {
  EXACT = "exact",
  CONTAINS = "contains",
  REGEX = "regex",
}

export enum ConversationTopic {
  RETURNS_EXCHANGES = "Returns or exchanges",
  ORDER_TRACKING = "Order tracking",
  MISSING_PACKAGE = "Missing package",
  DAMAGED_PACKAGE = "Damaged package",
  CANCEL_ORDER = "Cancel order",
  EDIT_ORDER = "Edit order",
  SHIPPING_INFORMATION = "Shipping information",
  DISCOUNTS_PROMOTIONS = "Discounts and promotions",
  STOCK_AVAILABILITY = "Stock availability",
  PARTNERSHIP_COLLABORATION = "Partnership or collaboration",
}

const fixupPlainDateJsonFormat: JsonFormat<Temporal.PlainDate> =
  bijectionCompose(stringJsonFormat, {
    read(value) {
      try {
        return Temporal.PlainDate.from(value);
      } catch {
        return new Date(value)
          .toTemporalInstant()
          .toZonedDateTimeISO(Temporal.Now.timeZoneId())
          .toPlainDate();
      }
    },
    write: String,
  });

export namespace Condition {
  export const COVERAGE = Symbol("coverage");
  export const PACKAGE_PROTECTION = Symbol("package_protection");
  export const CUSTOMER_TAG = Symbol("customer_tag");
  export const DELIVERY_WITHIN = Symbol("shipping_within");
  /** Discount, codes or otherwise */
  export const DISCOUNT = Symbol("discount_code");
  export const FULFILLMENT_WITHIN = Symbol("fulfilment_within");
  export const ITEMS_BEING_RETURNED_COUNT = Symbol(
    "items_being_returned_count",
  );
  export const ADJUSTMENT_AMOUNT = Symbol("adjustment_amount");
  export const RETURN_METHOD = Symbol("return_method");
  export const COMPENSATION_METHOD = Symbol("compensation_method");
  export const ORDER_DATE = Symbol("order_date");
  export const RETURN_DATE = Symbol("return_date");
  export const ORDER_WITHIN = Symbol("order_within");
  export const PRICE = Symbol("price");
  export const PRODUCT_PROPERTY = Symbol("product_property");
  export const PRODUCT_TAG = Symbol("product_tag");
  export const PRODUCT_TYPE = Symbol("product_type");
  export const PRODUCT_VARIANT_METAFIELD = Symbol("product_variant_metafield");
  export const ORDER_TAG = Symbol("order_tag");
  export const COLLECTION = Symbol("collection");
  export const SALES_CHANNEL = Symbol("sales_channel");
  export const CUSTOMER_COUNTRY = Symbol("customer_country");
  export const NUM_CUSTOMER_CLAIMS = Symbol("num_customer_claims");
  export const NUM_CUSTOMER_RETURNS = Symbol("num_customer_returns");
  export const NUM_ORDERS = Symbol("num_orders");
  export const RETURN_RATE = Symbol("return_rate");
  export const EXCHANGE_COUNT = Symbol("exchange_count");
  export const SKU = Symbol("sku");
  export const PAYMENT_METHOD = Symbol("payment_method");
  export const AFTER_HOURS = Symbol("after_hours");
  export const CUSTOMER_EMAIL_SUBSCRIBER = Symbol("customer_email_subscriber");
  export const CUSTOMER_SMS_SUBSCRIBER = Symbol("customer_sms_subscriber");
  export const FULFILLMENT_TRACKING_UPDATE_WITHIN = Symbol(
    "fulfillment_tracking_update_within",
  );
  export const FINAL_SALE_RETURN = Symbol("final_sale_return");
  export const ORDERLESS = Symbol("orderless");
  export const TICKET_CHANNEL = Symbol("ticket_channel");
  export const TOPIC = Symbol("topic");
  export const RECEIVED_AT_EMAIL = Symbol("received_at_email");
  export const SENT_FROM_EMAIL = Symbol("sent_from_email");
  export const SUBJECT_LINE = Symbol("subject_line");
  export const MESSAGE_BODY_MATCHES = Symbol("message_body_matches");
  export const ORDER_COUNT_GREATER_THAN = Symbol("order_count_greater_than");
  export const TOTAL_SPENT_GREATER_THAN = Symbol("total_spent_greater_than");
  export const VENDOR = Symbol("vendor");
  export const TRACKING_TYPE = Symbol("tracking_type");
  export const IS_REFUND_RETURN = Symbol("is_refund_return");

  export interface Coverage {
    type: typeof COVERAGE;
  }

  export const coverageJsonFormat: JsonFormat<Coverage> = objectJsonFormat(
    { type: symbolJsonFormat(COVERAGE) },
    {},
  );

  export interface PackageProtection {
    type: typeof PACKAGE_PROTECTION;
  }

  export const packageProtiectionJsonFormat: JsonFormat<PackageProtection> =
    objectJsonFormat({ type: symbolJsonFormat(PACKAGE_PROTECTION) }, {});

  export interface CustomerTag {
    type: typeof CUSTOMER_TAG;
    tags: readonly string[];
  }

  export const customerTagJsonFormat: JsonFormat<CustomerTag> =
    objectJsonFormat(
      {
        type: symbolJsonFormat(CUSTOMER_TAG),
        tags: arrayJsonFormat(stringJsonFormat),
      },
      {},
    );

  export interface Discount {
    type: typeof DISCOUNT;
    codes: readonly string[];
    codeOption: DiscountOptionModel | null;
    matchType?: DiscountMatchType | null;
  }

  export const discountJsonFormat: JsonFormat<Discount> = objectJsonFormat(
    {
      type: symbolJsonFormat(DISCOUNT),
      codes: arrayJsonFormat(stringJsonFormat),
      codeOption: nullableJsonFormat(
        stringJsonFormat as JsonFormat<DiscountOptionModel>,
      ),
    },
    {
      matchType: nullableJsonFormat(
        stringJsonFormat as JsonFormat<DiscountMatchType>,
      ),
    },
  );

  export interface FulfillmentPeriod {
    type: typeof FULFILLMENT_WITHIN;
    duration: Temporal.Duration;
  }

  export const fulfillmentPeriodJsonFormat: JsonFormat<FulfillmentPeriod> =
    objectJsonFormat(
      {
        type: symbolJsonFormat(FULFILLMENT_WITHIN),
        duration: temporalDurationJsonFormat,
      },
      {},
    );

  export interface FinalSaleReturn {
    type: typeof FINAL_SALE_RETURN;
    validProductTags: readonly string[];
    validCollections: readonly string[];
  }

  export const finalSaleReturnJsonFormat: JsonFormat<FinalSaleReturn> =
    objectJsonFormat(
      {
        type: symbolJsonFormat(FINAL_SALE_RETURN),
        validProductTags: arrayJsonFormat(stringJsonFormat),
        validCollections: arrayJsonFormat(stringJsonFormat),
      },
      {},
    );

  export interface Orderless {
    type: typeof ORDERLESS;
  }

  export const orderlessJsonFormat: JsonFormat<Orderless> = objectJsonFormat(
    { type: symbolJsonFormat(ORDERLESS) },
    {},
  );

  export interface ItemsBeingReturnedCount {
    type: typeof ITEMS_BEING_RETURNED_COUNT;
    numItems: number;
    numItemsOption: NumItemsBeingReturnedOption;
  }

  export const itemsBeingReturnedCountJsonFormat: JsonFormat<ItemsBeingReturnedCount> =
    objectJsonFormat(
      {
        type: symbolJsonFormat(ITEMS_BEING_RETURNED_COUNT),
        numItems: numberJsonFormat,
        numItemsOption:
          stringJsonFormat as JsonFormat<NumItemsBeingReturnedOption>,
      },
      {},
    );

  export interface AdjustmentAmount {
    type: typeof ADJUSTMENT_AMOUNT;
    adjustmentAmount: number;
    adjustmentAmountOption: AdjustmentAmountOption;
  }

  export const adjustmentAmountJsonFormat: JsonFormat<AdjustmentAmount> =
    objectJsonFormat(
      {
        type: symbolJsonFormat(ADJUSTMENT_AMOUNT),
        adjustmentAmount: numberJsonFormat,
        adjustmentAmountOption:
          stringJsonFormat as JsonFormat<AdjustmentAmountOption>,
      },
      {},
    );

  export interface ReturnMethod {
    type: typeof RETURN_METHOD;
    returnMethod: ReturnOptionMethod["type"];
  }

  export const returnMethodJsonFormat: JsonFormat<ReturnMethod> =
    objectJsonFormat(
      {
        type: symbolJsonFormat(RETURN_METHOD),
        returnMethod: symbolEnumJsonFormat([
          ReturnOptionMethod.EXCHANGE,
          ReturnOptionMethod.REFUND,
          ReturnOptionMethod.STORE_CREDIT,
        ]),
      },
      {},
    );

  export interface CompensationMethod {
    type: typeof COMPENSATION_METHOD;
    compensationMethod: ReturnOptionMethod["type"];
  }

  export const compensationMethodJsonFormat: JsonFormat<CompensationMethod> =
    objectJsonFormat(
      {
        type: symbolJsonFormat(COMPENSATION_METHOD),
        compensationMethod: symbolEnumJsonFormat([
          ReturnOptionMethod.EXCHANGE,
          ReturnOptionMethod.REFUND,
          ReturnOptionMethod.STORE_CREDIT,
        ]),
      },
      {},
    );

  export interface OrderDate {
    type: typeof ORDER_DATE;
    start: Temporal.PlainDate | null;
    end: Temporal.PlainDate | null;
    dateOption: DateOption | null;
  }

  export interface ReturnDate {
    type: typeof RETURN_DATE;
    start: Temporal.PlainDate | null;
    end: Temporal.PlainDate | null;
    dateOption: DateOption | null;
  }

  export const orderDateJsonFormat: JsonFormat<OrderDate | ReturnDate> =
    objectJsonFormat(
      {
        type: symbolJsonFormat(ORDER_DATE || RETURN_DATE),
        start: nullableJsonFormat(fixupPlainDateJsonFormat),
        end: nullableJsonFormat(fixupPlainDateJsonFormat),
        dateOption: nullableJsonFormat(
          stringJsonFormat as JsonFormat<DateOption>,
        ),
      },
      {},
    );

  export const returnDateJsonFormat: JsonFormat<ReturnDate> = objectJsonFormat(
    {
      type: symbolJsonFormat(RETURN_DATE),
      start: nullableJsonFormat(fixupPlainDateJsonFormat),
      end: nullableJsonFormat(fixupPlainDateJsonFormat),
      dateOption: nullableJsonFormat(
        stringJsonFormat as JsonFormat<DateOption>,
      ),
    },
    {},
  );

  export interface OrderWithin {
    type: typeof ORDER_WITHIN;
    duration: Temporal.Duration;
  }

  export const orderWithinFormat: JsonFormat<OrderWithin> = objectJsonFormat(
    {
      type: symbolJsonFormat(ORDER_WITHIN),
      duration: temporalDurationJsonFormat,
    },
    {},
  );

  export interface Price {
    type: typeof PRICE;
    amount: number;
    priceOption: PriceOption | null;
  }

  export const priceJsonFormat: JsonFormat<Price> = objectJsonFormat(
    {
      type: symbolJsonFormat(PRICE),
      amount: numberJsonFormat,
      priceOption: nullableJsonFormat(
        stringJsonFormat as JsonFormat<PriceOption>,
      ),
    },
    {},
  );

  export interface OrderTag {
    type: typeof ORDER_TAG;
    tags: readonly string[];
  }

  export const orderTagJson: JsonFormat<OrderTag> = objectJsonFormat(
    {
      type: symbolJsonFormat(ORDER_TAG),
      tags: arrayJsonFormat(stringJsonFormat),
    },
    {},
  );

  export interface ProductProperty {
    type: typeof PRODUCT_PROPERTY;
    properties: readonly string[];
  }

  export const productAttributeJson: JsonFormat<ProductProperty> =
    objectJsonFormat(
      {
        type: symbolJsonFormat(PRODUCT_PROPERTY),
        properties: arrayJsonFormat(stringJsonFormat),
      },
      {},
    );

  export interface ProductTag {
    type: typeof PRODUCT_TAG;
    tags: readonly string[];
  }

  export const productTagJson: JsonFormat<ProductTag> = objectJsonFormat(
    {
      type: symbolJsonFormat(PRODUCT_TAG),
      tags: arrayJsonFormat(stringJsonFormat),
    },
    {},
  );

  export interface Vendor {
    type: typeof VENDOR;
    vendors: readonly string[];
  }

  export const vendorJson: JsonFormat<Vendor> = objectJsonFormat(
    {
      type: symbolJsonFormat(VENDOR),
      vendors: arrayJsonFormat(stringJsonFormat),
    },
    {},
  );

  export interface TrackingType {
    type: typeof TRACKING_TYPE;
    trackingType: string;
  }

  export const trackingTypeJsonFormat: JsonFormat<TrackingType> =
    objectJsonFormat(
      {
        type: symbolJsonFormat(TRACKING_TYPE),
        trackingType: stringJsonFormat,
      },
      {},
    );

  export interface ProductType {
    type: typeof PRODUCT_TYPE;
    productTypes: readonly string[];
  }

  export const productTypeJson: JsonFormat<ProductType> = objectJsonFormat(
    {
      type: symbolJsonFormat(PRODUCT_TYPE),
      productTypes: arrayJsonFormat(stringJsonFormat),
    },
    {},
  );

  export interface ProductVariantMetafield {
    type: typeof PRODUCT_VARIANT_METAFIELD;
    namespace: string;
    key: string;
    comparison: MetafieldMatchType;
    value: string;
  }

  export const productVariantMetafieldJson: JsonFormat<ProductVariantMetafield> =
    objectJsonFormat(
      {
        type: symbolJsonFormat(PRODUCT_VARIANT_METAFIELD),
        namespace: stringJsonFormat,
        key: stringJsonFormat,
        comparison: stringEnumJsonFormat(MetafieldMatchType),
        value: stringJsonFormat,
      },
      {},
    );

  export interface Collection {
    type: typeof COLLECTION;
    collections: string[];
  }

  export const collectionJson: JsonFormat<Collection> = objectJsonFormat(
    {
      type: symbolJsonFormat(COLLECTION),
      collections: arrayJsonFormat(stringJsonFormat),
    },
    {},
  );

  export interface SalesChannel {
    type: typeof SALES_CHANNEL;
    channels: readonly string[];
  }

  export const salesChannelJson: JsonFormat<SalesChannel> = objectJsonFormat(
    {
      type: symbolJsonFormat(SALES_CHANNEL),
      channels: arrayJsonFormat(stringJsonFormat),
    },
    {},
  );

  export interface ShippingWithin {
    type: typeof DELIVERY_WITHIN;
    duration: Temporal.Duration;
  }

  export const deliveryWithinJson: JsonFormat<ShippingWithin> =
    objectJsonFormat(
      {
        type: symbolJsonFormat(DELIVERY_WITHIN),
        duration: temporalDurationJsonFormat,
      },
      {},
    );

  export interface CustomerCountry {
    type: typeof CUSTOMER_COUNTRY;
    countries: readonly string[];
  }

  export const customerCountryJsonFormat: JsonFormat<CustomerCountry> =
    objectJsonFormat(
      {
        type: symbolJsonFormat(CUSTOMER_COUNTRY),
        countries: arrayJsonFormat(stringJsonFormat),
      },
      {},
    );

  export interface OrderCountGreaterThan {
    type: typeof ORDER_COUNT_GREATER_THAN;
    orderCount: string;
  }

  export const orderCountJsonFormat: JsonFormat<OrderCountGreaterThan> =
    objectJsonFormat(
      {
        type: symbolJsonFormat(ORDER_COUNT_GREATER_THAN),
        orderCount: stringJsonFormat,
      },
      {},
    );

  export interface IsRefundReturn {
    type: typeof IS_REFUND_RETURN;
  }

  export const isRefundReturnJsonFormat: JsonFormat<IsRefundReturn> =
    objectJsonFormat({ type: symbolJsonFormat(IS_REFUND_RETURN) }, {});

  export interface TotalSpentGreaterThan {
    type: typeof TOTAL_SPENT_GREATER_THAN;
    totalSpent: string;
  }

  export const totalSpentJsonFormat: JsonFormat<TotalSpentGreaterThan> =
    objectJsonFormat(
      {
        type: symbolJsonFormat(TOTAL_SPENT_GREATER_THAN),
        totalSpent: stringJsonFormat,
      },
      {},
    );

  export interface NumCustomerClaims {
    type: typeof NUM_CUSTOMER_CLAIMS;
    numClaims: number;
    numClaimsOption: NumClaimsOption | null;
  }

  export const numCustomerClaimsJsonFormat: JsonFormat<NumCustomerClaims> =
    objectJsonFormat(
      {
        type: symbolJsonFormat(NUM_CUSTOMER_CLAIMS),
        numClaims: numberJsonFormat,
        numClaimsOption: nullableJsonFormat(
          stringJsonFormat as JsonFormat<NumClaimsOption>,
        ),
      },
      {},
    );

  export interface NumCustomerReturns {
    type: typeof NUM_CUSTOMER_RETURNS;
    numReturns: number;
    numReturnsOption: NumReturnsOption | null;
  }

  export const numCustomerReturnsJsonFormat: JsonFormat<NumCustomerReturns> =
    objectJsonFormat(
      {
        type: symbolJsonFormat(NUM_CUSTOMER_RETURNS),
        numReturns: numberJsonFormat,
        numReturnsOption: nullableJsonFormat(
          stringJsonFormat as JsonFormat<NumReturnsOption>,
        ),
      },
      {},
    );

  export interface ExchangeCount {
    type: typeof EXCHANGE_COUNT;
    numExchanges: number;
  }

  export const exchangeCountJsonFormat: JsonFormat<ExchangeCount> =
    objectJsonFormat(
      {
        type: symbolJsonFormat(EXCHANGE_COUNT),
        numExchanges: numberJsonFormat,
      },
      {},
    );

  export interface NumOrders {
    type: typeof NUM_ORDERS;
    numOrders: number;
    numOrdersOption: NumOrdersOption | null;
  }

  export const numOrdersJsonFormat: JsonFormat<NumOrders> = objectJsonFormat(
    {
      type: symbolJsonFormat(NUM_ORDERS),
      numOrders: numberJsonFormat,
      numOrdersOption: nullableJsonFormat(
        stringJsonFormat as JsonFormat<NumOrdersOption>,
      ),
    },
    {},
  );

  export interface ReturnRate {
    type: typeof RETURN_RATE;
    returnRate: number;
    comparison: ReturnRateOption | null;
  }

  export const returnRateJsonFormat: JsonFormat<ReturnRate> = objectJsonFormat(
    {
      type: symbolJsonFormat(RETURN_RATE),
      returnRate: numberJsonFormat,
      comparison: nullableJsonFormat(
        stringJsonFormat as JsonFormat<ReturnRateOption>,
      ),
    },
    {},
  );

  export interface Sku {
    type: typeof SKU;
    codes: readonly string[];
    skuOption: SkuOption | null;
  }

  export const skuJsonFormat: JsonFormat<Sku> = objectJsonFormat(
    {
      type: symbolJsonFormat(SKU),
      codes: arrayJsonFormat(stringJsonFormat),
      skuOption: nullableJsonFormat(stringJsonFormat as JsonFormat<SkuOption>),
    },
    {},
  );

  export interface PaymentMethod {
    type: typeof PAYMENT_METHOD;
    method: PaymentMethodOption | null;
  }

  export const paymentMethodJsonFormat: JsonFormat<PaymentMethod> =
    objectJsonFormat(
      {
        type: symbolJsonFormat(PAYMENT_METHOD),
        method: nullableJsonFormat(
          stringJsonFormat as JsonFormat<PaymentMethodOption>,
        ),
      },
      {},
    );

  export interface AfterHours {
    type: typeof AFTER_HOURS;
  }

  export const afterHoursJsonFormat: JsonFormat<AfterHours> = objectJsonFormat(
    { type: symbolJsonFormat(AFTER_HOURS) },
    {},
  );

  export interface CustomerEmailSubscriber {
    type: typeof CUSTOMER_EMAIL_SUBSCRIBER;
  }

  export const customerEmailSubscriberJsonFormat: JsonFormat<CustomerEmailSubscriber> =
    objectJsonFormat({ type: symbolJsonFormat(CUSTOMER_EMAIL_SUBSCRIBER) }, {});

  export interface CustomerSmsSubscriber {
    type: typeof CUSTOMER_SMS_SUBSCRIBER;
  }

  export const customerSmsSubscriberJsonFormat: JsonFormat<CustomerSmsSubscriber> =
    objectJsonFormat({ type: symbolJsonFormat(CUSTOMER_SMS_SUBSCRIBER) }, {});

  export interface FulfillmentTrackingUpdateWithin {
    type: typeof FULFILLMENT_TRACKING_UPDATE_WITHIN;
    duration: Temporal.Duration;
  }

  export const packageTrackingUpdateWithinJsonFormat: JsonFormat<FulfillmentTrackingUpdateWithin> =
    objectJsonFormat(
      {
        type: symbolJsonFormat(FULFILLMENT_TRACKING_UPDATE_WITHIN),
        duration: temporalDurationJsonFormat,
      },
      {},
    );

  export interface TicketChannel {
    type: typeof TICKET_CHANNEL;
    channels: ConversationPlatform[];
  }

  export const ticketChannelJsonFormat: JsonFormat<TicketChannel> =
    objectJsonFormat(
      {
        type: symbolJsonFormat(TICKET_CHANNEL),
        channels: arrayJsonFormat(
          stringJsonFormat as JsonFormat<ConversationPlatform>,
        ),
      },
      {},
    );

  export interface Topic {
    type: typeof TOPIC;
    topics: ConversationTopic[];
  }

  export interface ReceivedAtEmail {
    type: typeof RECEIVED_AT_EMAIL;
    emails: string[];
  }

  export interface SentFromEmail {
    type: typeof SENT_FROM_EMAIL;
    emails: string[];
    matchMode: "INCLUDES" | "IS_EXACTLY";
  }

  export interface SubjectLine {
    type: typeof SUBJECT_LINE;
    fuzzy_match: string;
  }

  export interface MessageBodyMatches {
    type: typeof MESSAGE_BODY_MATCHES;
    queryString?: string;
    queryStrings?: string[];
    caseSensitive: boolean;
    matchMode: "INCLUDES" | "IS_EXACTLY";
  }

  export const subjectLineJsonFormat: JsonFormat<SubjectLine> =
    objectJsonFormat(
      {
        type: symbolJsonFormat(SUBJECT_LINE),
        fuzzy_match: stringJsonFormat,
      },
      {},
    );
  export const messageBodyMatchesJsonFormat: JsonFormat<MessageBodyMatches> =
    objectJsonFormat(
      {
        type: symbolJsonFormat(MESSAGE_BODY_MATCHES),
        caseSensitive: booleanJsonFormat,
        matchMode: stringEnumJsonFormat({
          INCLUDES: "INCLUDES",
          IS_EXACTLY: "IS_EXACTLY",
        }),
      },
      {
        queryString: stringJsonFormat,
        queryStrings: arrayJsonFormat(stringJsonFormat),
      },
    );

  export const topicJsonFormat: JsonFormat<Topic> = objectJsonFormat(
    {
      type: symbolJsonFormat(TOPIC),
      topics: arrayJsonFormat(
        stringJsonFormat as JsonFormat<ConversationTopic>,
      ),
    },
    {},
  );

  export const receivedAtEmailJsonFormat: JsonFormat<ReceivedAtEmail> =
    objectJsonFormat(
      {
        type: symbolJsonFormat(RECEIVED_AT_EMAIL),
        emails: arrayJsonFormat(stringJsonFormat),
      },
      {},
    );

  export const sentFromEmailJsonFormat: JsonFormat<SentFromEmail> =
    objectJsonFormat(
      {
        type: symbolJsonFormat(SENT_FROM_EMAIL),
        emails: arrayJsonFormat(stringJsonFormat),
        matchMode: stringEnumJsonFormat({
          INCLUDES: "INCLUDES",
          IS_EXACTLY: "IS_EXACTLY",
        }),
      },
      {},
    );
}

export const conditionJsonFormat: JsonFormat<Condition> = symbolUnionJsonFormat(
  "type",
  "type",
  {
    [Condition.COVERAGE]: Condition.coverageJsonFormat,
    [Condition.PACKAGE_PROTECTION]: Condition.packageProtiectionJsonFormat,
    [Condition.CUSTOMER_TAG]: Condition.customerTagJsonFormat,
    [Condition.CUSTOMER_COUNTRY]: Condition.customerCountryJsonFormat,
    [Condition.DELIVERY_WITHIN]: Condition.deliveryWithinJson,
    [Condition.DISCOUNT]: Condition.discountJsonFormat,
    [Condition.EXCHANGE_COUNT]: Condition.exchangeCountJsonFormat,
    [Condition.FULFILLMENT_WITHIN]: Condition.fulfillmentPeriodJsonFormat,
    [Condition.FINAL_SALE_RETURN]: Condition.finalSaleReturnJsonFormat,
    [Condition.ORDERLESS]: Condition.orderlessJsonFormat,
    [Condition.ITEMS_BEING_RETURNED_COUNT]:
      Condition.itemsBeingReturnedCountJsonFormat,
    [Condition.ADJUSTMENT_AMOUNT]: Condition.adjustmentAmountJsonFormat,
    [Condition.RETURN_METHOD]: Condition.returnMethodJsonFormat,
    [Condition.ORDER_DATE]: Condition.orderDateJsonFormat,
    [Condition.RETURN_DATE]: Condition.returnDateJsonFormat,
    [Condition.ORDER_WITHIN]: Condition.orderWithinFormat,
    [Condition.PRICE]: Condition.priceJsonFormat,
    [Condition.PRODUCT_PROPERTY]: Condition.productAttributeJson,
    [Condition.PRODUCT_TAG]: Condition.productTagJson,
    [Condition.PRODUCT_TYPE]: Condition.productTypeJson,
    [Condition.PRODUCT_VARIANT_METAFIELD]:
      Condition.productVariantMetafieldJson,
    [Condition.ORDER_TAG]: Condition.orderTagJson,
    [Condition.COLLECTION]: Condition.collectionJson,
    [Condition.SALES_CHANNEL]: Condition.salesChannelJson,
    [Condition.COMPENSATION_METHOD]: Condition.compensationMethodJsonFormat,
    [Condition.NUM_CUSTOMER_CLAIMS]: Condition.numCustomerClaimsJsonFormat,
    [Condition.NUM_CUSTOMER_RETURNS]: Condition.numCustomerReturnsJsonFormat,
    [Condition.NUM_ORDERS]: Condition.numOrdersJsonFormat,
    [Condition.RETURN_RATE]: Condition.returnRateJsonFormat,
    [Condition.SKU]: Condition.skuJsonFormat,
    [Condition.PAYMENT_METHOD]: Condition.paymentMethodJsonFormat,
    [Condition.AFTER_HOURS]: Condition.afterHoursJsonFormat,
    [Condition.FULFILLMENT_TRACKING_UPDATE_WITHIN]:
      Condition.packageTrackingUpdateWithinJsonFormat,
    [Condition.CUSTOMER_EMAIL_SUBSCRIBER]:
      Condition.customerEmailSubscriberJsonFormat,
    [Condition.CUSTOMER_SMS_SUBSCRIBER]:
      Condition.customerSmsSubscriberJsonFormat,
    [Condition.TICKET_CHANNEL]: Condition.ticketChannelJsonFormat,
    [Condition.TOPIC]: Condition.topicJsonFormat,
    [Condition.RECEIVED_AT_EMAIL]: Condition.receivedAtEmailJsonFormat,
    [Condition.SENT_FROM_EMAIL]: Condition.sentFromEmailJsonFormat,
    [Condition.SUBJECT_LINE]: Condition.subjectLineJsonFormat,
    [Condition.MESSAGE_BODY_MATCHES]: Condition.messageBodyMatchesJsonFormat,
    [Condition.ORDER_COUNT_GREATER_THAN]: Condition.orderCountJsonFormat,
    [Condition.TOTAL_SPENT_GREATER_THAN]: Condition.totalSpentJsonFormat,
    [Condition.TRACKING_TYPE]: Condition.trackingTypeJsonFormat,
    [Condition.IS_REFUND_RETURN]: Condition.isRefundReturnJsonFormat,
  },
);

export interface DiscountName {
  code: string;
  title: string;
}

export type Coverage = typeof Coverage.COVERED | typeof Coverage.NOT_COVERED;

export namespace Coverage {
  export const COVERED = Symbol("covered");
  export const NOT_COVERED = Symbol("not_covered");
}

export interface Parameters {
  /** Discount codes, titles, and descriptions on the order */
  coverage: Coverage;
  packageProtection: Coverage;
  finalSaleReturns: Coverage;
  orderDiscounts: DiscountName[];
  orderName?: string;
  productDiscounts: DiscountName[];
  now: Temporal.Instant;
  fulfilledOn: Temporal.Instant;
  deliveredOn: Temporal.Instant;
  orderedOn: Temporal.Instant;
  customerTags: string[];
  customerCountry: string;
  price: number;
  productProperties: string[];
  productTags: string[];
  productType: string;
  productVariantMetafields: Record<string, string>;
  numItemsBeingReturned: number;
  orderTags: string[];
  salesChannel: string;
  collections: string[];
  numCustomerClaims: number;
  numCustomerReturns: number;
  numOrders?: number;
  returnRate?: number;
  exchangeCount: number;
  sku: string[];
  method: string[];
  adjustmentAmount: number;
  returnMethod: ReturnOptionMethod["type"] | undefined;
  compensationMethods: ReturnOptionMethod["type"][] | undefined;
  // built for claim flow only (may be undefined in flows before an item is fulfilled)
  lastFulfillmentUpdateOn: Temporal.Instant | undefined;
}

export interface ChatParameters {
  afterHours: boolean;
}

export interface EmailParameters {
  customerEmailSubscriber: boolean;
  customerSmsSubscriber: boolean;
  customerCountry: string;
}

const evaluateDate = (
  start: Temporal.PlainDate,
  end: Temporal.PlainDate | null,
  condition: DateOption,
  compareTo: Temporal.PlainDate,
) => {
  switch (condition) {
    case DateOption.BEFORE:
      return Temporal.PlainDate.compare(compareTo, start) <= 0;
    case DateOption.AFTER:
      return Temporal.PlainDate.compare(start, compareTo) <= 0;
    case DateOption.BETWEEN:
      return (
        Temporal.PlainDate.compare(start, compareTo) <= 0 &&
        Temporal.PlainDate.compare(compareTo, end!) <= 0
      );
  }
};

const evaluateDiscountCode = (
  discounts: DiscountName[],
  matchType: DiscountMatchType,
  names: Set<string>,
) => {
  switch (matchType) {
    case "contains":
      return discounts.some((discount: DiscountName) => {
        for (const name of names) {
          const code = discount.code.toLowerCase().trim() || null;
          const title = discount.title.toLowerCase().trim() || null;
          if (
            (code && code.includes(name)) ||
            (title && title.includes(name))
          ) {
            return true;
          }
        }
        return false;
      });
    // "exact" | null
    default:
      return discounts.some(
        (discount: DiscountName) =>
          names.has(discount.code.toLowerCase().trim()) ||
          names.has(discount.title.toLowerCase().trim()),
      );
  }
};

export namespace Condition {
  export function evaluate(
    condition: Condition,
    parameters: Parameters,
  ): boolean {
    switch (condition.type) {
      case Condition.COVERAGE:
        return parameters.coverage === Coverage.COVERED;
      case Condition.PACKAGE_PROTECTION:
        return parameters.packageProtection === Coverage.COVERED;
      case Condition.CUSTOMER_TAG:
        return condition.tags.some((tag) =>
          parameters.customerTags.includes(tag),
        );
      case Condition.DISCOUNT: {
        const names = new Set(
          condition.codes.map((name) => name.toLowerCase().trim()),
        );
        const discounts =
          condition.codeOption == "order"
            ? parameters.orderDiscounts
            : parameters.productDiscounts;
        return evaluateDiscountCode(discounts, condition.matchType!, names);
      }
      case Condition.FULFILLMENT_WITHIN:
        return (
          Temporal.Instant.compare(
            parameters.now,
            instantAddUtc(parameters.fulfilledOn, condition.duration),
          ) < 0
        );
      case Condition.FINAL_SALE_RETURN:
        return parameters.finalSaleReturns === Coverage.COVERED;
      case Condition.ORDERLESS:
        return parameters.orderName === "Third-party";
      case Condition.ITEMS_BEING_RETURNED_COUNT:
        switch (condition.numItemsOption) {
          case NumItemsBeingReturnedOption.LESS_THAN:
            return parameters.numItemsBeingReturned < condition.numItems;
          case NumItemsBeingReturnedOption.GREATER_THAN:
            return parameters.numItemsBeingReturned > condition.numItems;
          case NumItemsBeingReturnedOption.EQUALS:
            return parameters.numItemsBeingReturned === condition.numItems;
        }
        return false;
      case Condition.ADJUSTMENT_AMOUNT:
        switch (condition.adjustmentAmountOption) {
          case AdjustmentAmountOption.LESS_THAN:
            return parameters.adjustmentAmount < condition.adjustmentAmount;
          case AdjustmentAmountOption.GREATER_THAN:
            return parameters.adjustmentAmount > condition.adjustmentAmount;
          case AdjustmentAmountOption.EQUALS:
            return (
              parameters.adjustmentAmount.toFixed(2) ===
              condition.adjustmentAmount.toFixed(2)
            );
        }
        return false;
      case Condition.RETURN_METHOD:
        return parameters.returnMethod === condition.returnMethod;
      case Condition.ORDER_DATE:
        return evaluateDate(
          condition.start!,
          condition.end,
          condition.dateOption!,
          parameters.orderedOn
            .toZonedDateTimeISO(Temporal.Now.timeZoneId())
            .toPlainDate(),
        );
      case Condition.RETURN_DATE:
        return evaluateDate(
          condition.start!,
          condition.end,
          condition.dateOption!,
          parameters.now
            .toZonedDateTimeISO(Temporal.Now.timeZoneId())
            .toPlainDate(),
        );
      case Condition.ORDER_WITHIN:
        return (
          Temporal.Instant.compare(
            parameters.now,
            instantAddUtc(parameters.orderedOn, condition.duration),
          ) < 0
        );
      case Condition.PRICE:
        switch (condition.priceOption!) {
          case PriceOption.EQUALS:
            return parameters.price.toFixed(2) === condition.amount.toFixed(2);
          case PriceOption.LESS_THAN:
            return parameters.price < condition.amount;
          case PriceOption.GREATER_THAN:
            return parameters.price > condition.amount;
          case PriceOption.CENTS_EQUALS:
            // Just get the cents values from each.
            return (
              parameters.price.toFixed(2).slice(-2) ===
              condition.amount.toFixed(2).slice(-2)
            );
        }
        return false;
      case Condition.PRODUCT_TAG: {
        const productTags =
          parameters.productTags?.map((tag) => tag.toLowerCase().trim()) || [];
        return condition.tags.some((tag) =>
          productTags.includes(tag.toLowerCase().trim()),
        );
      }
      case Condition.PRODUCT_PROPERTY: {
        const productProperties =
          parameters.productProperties?.map((property) =>
            property.toLowerCase().trim(),
          ) || [];
        return condition.properties.some((property) =>
          productProperties.includes(property.toLowerCase().trim()),
        );
      }
      case Condition.PRODUCT_TYPE: {
        const productType = parameters.productType?.toLowerCase() || "";
        return condition.productTypes
          .map((t) => t.toLowerCase())
          .includes(productType);
      }
      case Condition.PRODUCT_VARIANT_METAFIELD: {
        const value =
          parameters.productVariantMetafields?.[
            `${condition.namespace}.${condition.key}`
          ];
        if (!value) {
          return false;
        }
        switch (condition.comparison) {
          case MetafieldMatchType.EXACT:
            return value === condition.value;
          case MetafieldMatchType.CONTAINS:
            return value.includes(condition.value);
          case MetafieldMatchType.REGEX:
            try {
              return new RegExp(condition.value).test(value);
            } catch (e) {
              console.error("Invalid regex", e);
              return false;
            }
        }
        return false;
      }
      case Condition.ORDER_TAG: {
        const orderTags =
          parameters.orderTags?.map((tag) => tag.toLowerCase().trim()) || [];
        return condition.tags.some((tag) =>
          orderTags.includes(tag.toLowerCase().trim()),
        );
      }
      case Condition.COLLECTION: {
        const collections =
          parameters.collections?.map((collection) =>
            collection.toLowerCase(),
          ) || [];
        return condition.collections.some((collection) =>
          collections.includes(collection.toLowerCase()),
        );
      }
      case Condition.DELIVERY_WITHIN:
        return (
          Temporal.Instant.compare(
            parameters.now,
            instantAddUtc(parameters.deliveredOn, condition.duration),
          ) < 0
        );
      case Condition.SALES_CHANNEL: {
        let found = false;
        SalesChannels.forEach((channel: Channel) => {
          if (condition.channels.includes(channel.id)) {
            channel.sourceNames.forEach((sourceName: string) => {
              if (parameters.salesChannel.startsWith(sourceName)) {
                found = true;
              }
            });
          }
        });
        return found;
      }
      case Condition.CUSTOMER_COUNTRY:
        return (
          // If we don't have the country for some reason, we assume it's allowed.
          !parameters.customerCountry ||
          // Otherwise only allow if the country is in the list.
          condition.countries.includes(parameters.customerCountry)
        );
      case Condition.NUM_CUSTOMER_CLAIMS:
        switch (condition.numClaimsOption!) {
          case NumClaimsOption.LESS_THAN:
            return parameters.numCustomerClaims < condition.numClaims;
          case NumClaimsOption.GREATER_THAN:
            return parameters.numCustomerClaims > condition.numClaims;
        }
        return false;
      case Condition.NUM_CUSTOMER_RETURNS:
        switch (condition.numReturnsOption!) {
          case NumReturnsOption.LESS_THAN:
            return parameters.numCustomerReturns < condition.numReturns;
          case NumReturnsOption.GREATER_THAN:
            return parameters.numCustomerReturns > condition.numReturns;
        }
        return false;
      case Condition.NUM_ORDERS:
        if (parameters.numOrders === undefined) {
          // Sometimes shopify doesn't give us this value
          return true;
        }
        switch (condition.numOrdersOption!) {
          case NumOrdersOption.LESS_THAN:
            return parameters.numOrders < condition.numOrders;
          case NumOrdersOption.GREATER_THAN:
            return parameters.numOrders > condition.numOrders;
        }
        return false;
      case Condition.RETURN_RATE:
        // If shopify didn't give us numOrders, we can't calculate returnRate
        if (parameters.returnRate === undefined) {
          return true;
        }
        switch (condition.comparison!) {
          case ReturnRateOption.LESS_THAN:
            return parameters.returnRate < condition.returnRate;
          case ReturnRateOption.GREATER_THAN:
            return parameters.returnRate > condition.returnRate;
        }
        return false;
      case Condition.EXCHANGE_COUNT:
        return parameters.exchangeCount < condition.numExchanges;
      case Condition.SKU:
        switch (condition.skuOption!) {
          case SkuOption.EQUALS:
            return parameters.sku.some((sku) => condition.codes.includes(sku));
          case SkuOption.STARTS_WITH:
            return parameters.sku.some((sku) =>
              condition.codes.some((code) => {
                return sku.startsWith(code);
              }),
            );
          case SkuOption.ENDS_WITH:
            return parameters.sku.some((sku) =>
              condition.codes.some((code) => {
                return sku.endsWith(code);
              }),
            );
          case SkuOption.INCLUDES:
            return parameters.sku.some((sku) =>
              condition.codes.some((code) => {
                return sku.includes(code);
              }),
            );
        }
        return false;
      case Condition.PAYMENT_METHOD:
        switch (condition.method) {
          case PaymentMethodOption.GIFT_CARD:
            return parameters.method.includes("gift_card");
          case PaymentMethodOption.MANUAL:
            return parameters.method.some((method) =>
              method.startsWith("manual"),
            );
          case PaymentMethodOption.OTHER:
            return (
              !parameters.method.includes("gift_card") &&
              parameters.method.every((method) => !method.startsWith("manual"))
            );
        }
        return false;
      case Condition.FULFILLMENT_TRACKING_UPDATE_WITHIN:
        if (parameters.lastFulfillmentUpdateOn === undefined) {
          return false;
        }
        return (
          Temporal.Instant.compare(
            parameters.now,
            instantAddUtc(
              parameters.lastFulfillmentUpdateOn,
              condition.duration,
            ),
          ) < 0
        );
      case Condition.COMPENSATION_METHOD:
        if (parameters.compensationMethods === undefined) {
          return false;
        }
        return parameters.compensationMethods.some(
          (method) => condition.compensationMethod === method,
        );
    }
    return false;
  }

  export function evaluateEmail(
    condition: Condition,
    parameters: EmailParameters,
  ): boolean {
    switch (condition.type) {
      case Condition.CUSTOMER_EMAIL_SUBSCRIBER:
        return parameters.customerEmailSubscriber;
      case Condition.CUSTOMER_SMS_SUBSCRIBER:
        return parameters.customerSmsSubscriber;
      case Condition.CUSTOMER_COUNTRY:
        return (
          // If we don't have the country for some reason, we assume it's allowed.
          !parameters.customerCountry ||
          // Otherwise only allow if the country is in the list.
          condition.countries.includes(parameters.customerCountry)
        );
    }
    return false;
  }

  export function evaluateChat(
    condition: Condition,
    parameters: ChatParameters,
  ): boolean {
    switch (condition.type) {
      case Condition.AFTER_HOURS:
        return parameters.afterHours;
    }
    return false;
  }
}
